import axios from "axios";
import Swal from "sweetalert2";

const config = {
  //API_URL: "https://apivb.karakayasonmez.com/api/v1",
  //API_URL: "http://localhost/projects/ApiVilbox/public_html/api/v1",
  //API_URL: "https://testapi.vilbox.com/api/v1",
  API_URL: "https://api.vilbox.com/api/v1",
};

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// Language set to en always.
axios.interceptors.request.use((config) => {
  config.params = { ...config.params, lang: "tr" };
  return config;
});

// Error message handling.
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data.messages?.[3]) {
      Swal.fire({
        title: "Başarılı",
        text: response.data.messages[3].join("<br/>"),
        icon: "success",
        toast: true,
        timer: 2000,
        timerProgressBar: true,
        position: "bottom-start",
        showConfirmButton: false,
        customClass: {
          popup: "bg-light-info",
        },
      });
    }
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
      Swal.fire({
        title: "Hata",
        text: error.response.data.messages.map((t: any) =>
          Object.values(t).join("\n")
        ),
        icon: "error",
      });
    }
    return error;
  }
);
export default config;
